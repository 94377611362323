// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  TRAMITE_SHARED_URL: 'https://apm-aeu-sds-dev-shared.azure-api.net/tramites-shared/api',
  TRAMITE_SECURITY_URL: 'https://apm-aeu-sds-dev-shared.azure-api.net/security/api',
  T16_REQUEST_API_URL: 'https://apm-aeu-sds-dev-shared.azure-api.net/Tramites-Recoperjuridica-Ciu/api',
  //T16_REQUEST_API_URL: 'https://app-sds-recoperjuridica-ciu-dev-eastus.azurewebsites.net/api',
  //T16_VALIDATION_API_URL:'https://localhost:5001/validationApi',
  T16_VALIDATION_API_URL: 'https://apm-aeu-sds-dev-shared.azure-api.net/Tramites-Recoperjuridica-Fun/validationApi',
  //T16_VALIDATION_API_URL: 'https://app-sds-recoperjuridica-fun-dev-eastus.azurewebsites.net/validationApi',
  LOCALHOST: 'https://localhost:5001/validationApi',

  B2C_CONFIG: {
    TENANT_NAME: 'saludcapitalb2c',
    CLIENT_ID: '6dfed1c9-50b8-49e6-8bd6-09d850f5ccba',
    SIGN_UP_SIGN_IN: 'b2c_1_InicioSesionConRegistro'
  },
  VUDTS_URL: 'https://dev.ventanillavirtualtramites.saludcapital.gov.co',
  VUDTS_URL_LOCAL: 'localhost:4200',
  MAX_SIZE_MB_FILES: 50
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
